<template>
  <div class="readme-article">
    <h1 id="报考-成绩">
      四、报考&成绩
    </h1>
    <h2 id="成绩查询">1.成绩查询</h2>
    <p>
      查询员工已添加的班级学员的成绩，员工可修改学员成绩。
    </p>
    <p>
      <img src="@/assets/img/education/jw4-1.png">
    </p>
    <h2 id="添加成绩">2.添加成绩</h2>
    <p>
      员工可对自己负责的结课班级学员添加成绩，系统会自动显示需要添加成绩的学员列表。
    </p>
    <p>
      <img src="@/assets/img/education/jw4-2.png">
    </p>
    <h2 id="学员报考">3.学员报考</h2>
    <h3 id="学员报考列表">3.1学员报考列表</h3>
    <p>
      查看可报考的正式学员信息，点击报考按钮可前往报考科目。
    </p>
    <p>
      <img src="@/assets/img/education/jw4-3.png">
    </p>
    <h3 id="学员报考科目配置">3.2学员报考科目配置</h3>
    <p>
      学员报考的期数需要在设置-->教务-->报考期数先行配置。
    </p>
    <p>
      <img src="@/assets/img/education/jw4-4.png">
    </p>
    <h2 id="报考记录查询">4.报考记录查询</h2>
    <p>
      学员报考的记录查询信息，支持打印报考单。
    </p>
    <p>
      <img src="@/assets/img/education/jw4-5.png">
    </p>
    <h2 id="报考费流水">5.报考费流水</h2>
    <p>
      报考费流水记录，系统支持撤销和删除流水记录。
    </p>
    <p>
      <img src="@/assets/img/education/jw4-6.png">
    </p>
    <h2 id="报考撤销审批">6.报考撤销审批</h2>
    <p>
      学生在报考端报考科目是可以撤销的，当天撤销不需要教师审批，超过当天撤销则会在该栏目显示，员工审批后撤销。
    </p>
    <h2 id="报考费流水回收站">7.报考费流水回收站</h2>
    <p>
      报考费流水记录撤销记录，撤销的流水记录会在流水回收站显示。
    </p>
    <p>
      <img src="@/assets/img/education/jw4-7.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Education4-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>